import React, { ReactElement, useState } from 'react';
import { Modal } from '../Modal';
import { SignupForm } from './SignupForm';
import { SuccesfullSignup } from './SuccesfullSignup';

export const NewsletterSignupModal = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setDone(false);
  };
  const onSuccess = () => {
    setDone(true);
  };

  return (
    <>
      <button
        type="button"
        className="text-gold-default hover:text-gold-brighter underline"
        onClick={openModal}
      >
        Prijavite se odmah
      </button>
      <Modal title="Prijavite se" open={open} onClose={closeModal}>
        {done ? (
          <SuccesfullSignup onClose={closeModal} />
        ) : (
          <SignupForm onCancel={closeModal} onSuccess={onSuccess} />
        )}
      </Modal>
    </>
  );
};
