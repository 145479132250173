import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { HeroContactQuery } from '@/../graphql-types';
import { heroImg, heroImgWrapper } from '@/styles/hero.module.css';
import { HeroProps } from './types';

const HeroContact = ({
  className = heroImgWrapper,
  imgClassName = heroImg,
}: HeroProps): ReactElement => {
  const { desktop } = useStaticQuery<HeroContactQuery>(graphql`
    query HeroContact {
      desktop: file(relativePath: { eq: "hero-contact.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={desktop.childImageSharp.gatsbyImageData}
      alt="Boce ulja"
      loading="eager"
      className={className}
      imgClassName={imgClassName}
    />
  );
};

export default HeroContact;
