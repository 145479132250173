import React, { ReactElement, ReactNode } from 'react';
import { ReactComponent as Close } from '../assets/close.svg';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  title: string;
}

const Modal = ({
  title,
  open,
  onClose,
  children,
}: ModalProps): ReactElement => {
  const displayClass = open ? 'flex' : 'hidden';
  return (
    <div
      className={`${displayClass} fixed bg-gray-dark bg-opacity-90 z-50 left-0 right-0 top-0 bottom-0 text-gray-dark justify-center items-center`}
    >
      <div className="w-full flex flex-col bg-white rounded border-gold-default border-2 max-w-2xl">
        <div className="w-full flex justify-between px-4 py-4 font-bold text-xl">
          <div className="flex-grow">{title}</div>
          <button
            className="flex-shrink"
            type="button"
            aria-label="Zatvori"
            onClick={onClose}
          >
            <Close style={{ height: '1em' }} />
          </button>
        </div>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};
Modal.defaultProps = { children: undefined };

export { Modal };
