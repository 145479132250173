import React, { ReactElement, useMemo, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { FormField } from '../FormField';
import privacyPolicy from '@/assets/kota-privacy.pdf';

const matchEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

interface FormState {
  email: string;
  firstName: string;
  lastName: string;
}

export interface SignupFormProps {
  onCancel: () => void;
  onSuccess: () => void;
}

export const SignupForm = ({
  onSuccess,
  onCancel,
}: SignupFormProps): ReactElement => {
  const [formData, setFormData] = useState<FormState>({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [accepted, setAccepted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const setField = <K extends keyof FormState>(key: K, val: FormState[K]) => {
    setFormData({ ...formData, [key]: val });
  };
  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    console.log('Submitting: ', formData);
    setSubmitting(true);
    const resp = await addToMailchimp(formData.email, {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
    });
    console.log('Submitted. Got response: ', resp);
    setSubmitting(false);
    onSuccess();
  };

  const canSubmit = useMemo(
    () =>
      matchEmail.test(formData.email) &&
      formData.firstName.length > 0 &&
      formData.lastName.length > 0 &&
      accepted,
    [formData.email, formData.firstName, formData.lastName, accepted],
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full px-4 py-2">
        <FormField
          label="E-mail"
          name="email"
          value={formData.email}
          onChange={(val) => setField('email', val)}
          type="email"
          autoComplete="email"
        />
        <FormField
          label="Ime"
          name="firstName"
          value={formData.firstName}
          onChange={(val) => setField('firstName', val)}
          type="text"
          autoComplete="given-name"
        />
        <FormField
          label="Prezime"
          name="lastName"
          value={formData.lastName}
          onChange={(val) => setField('lastName', val)}
          type="text"
          autoComplete="family-name"
        />
        <label htmlFor="accept-conditions" className="text-lg">
          <input
            type="checkbox"
            id="accept-conditions"
            name="acceptConditions"
            checked={accepted}
            onChange={(ev) => {
              setAccepted(ev.target.checked);
            }}
          />{' '}
          Prihvaćam{' '}
          <a
            href={privacyPolicy}
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            pravila privatnosti
          </a>{' '}
          i želim primati obavijesti
        </label>
      </div>
      <div className="flex w-full justify-end px-4 py-2">
        <button
          type="button"
          onClick={onCancel}
          className="text-gray-medium hover:text-gray-dark mr-2 px-4 py-2"
        >
          Odustani
        </button>

        <button
          type="submit"
          className="btn disabled:bg-gold-pale disabled:cursor-not-allowed disabled:hover:bg-gold-pale"
          disabled={!canSubmit || submitting}
        >
          Potvrdi
        </button>
      </div>
    </form>
  );
};
