// Was wrongly showing warnings when passing name as prop
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement, InputHTMLAttributes } from 'react';

export interface FormFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  label: string;
  name: string;
  onChange: (newVal: string) => void;
  value: string;
}

export const FormField = ({
  label: inputLabel,
  name,
  onChange,
  value,
  ...inputProps
}: FormFieldProps): ReactElement => (
  <div className="w-full text-gray-medium focus-within:text-gray-dark">
    <label htmlFor={name} className="text-lg px-2 font-bold">
      {inputLabel}:
    </label>
    <input
      id={name}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      className="input mb-12"
      {...inputProps}
    />
  </div>
);
