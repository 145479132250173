import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import BgImage from '@/components/BgImage';
import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';

import { ReactComponent as PhoneIcon } from '@/assets/icon-phone.svg';
import { ReactComponent as LocationIcon } from '@/assets/icon-location.svg';
import { ReactComponent as ListIcon } from '@/assets/icon-list.svg';
import Map from '@/components/Map';
import Head from '@/components/Head';
import HeroContact from '@/components/heroes/HeroContact';
import { NewsletterSignupModal } from '@/components/NewsletterSignupModal';
import { ContactForm } from '@/components/ContactForm';

export default function ContactPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Head title={`${t('contact.contact')} | ${t('kota_olive_oil')}`} />

      <NavBar />
      {/* Hero with it's own bg image */}
      <main className="h-9/16 md:h-screen" style={{ paddingTop: '60px' }}>
        <div className="w-full h-full d-flex relative -z-1">
          {/* Fix for borked lighthouse tooling expecting content and failing to give score */}
          <div className="absolute left-0 top-0" aria-hidden>
            Kota
          </div>
          <HeroContact />
        </div>
      </main>

      {/* Everything that will have the bg image goes here, BgImage is contained here */}
      <div className="relative">
        <BgImage />
        <section className="py-16 md:py-32 lg:py-48">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto w-full items-center text-center md:text-left">
            <h1 className="text-3xl xl:text-4xl text-gold-default font-serif text-center mb-16">
              {t('contact.intro1')}
              <br />
              {t('contact.intro2')}
            </h1>

            <div className="flex flex-wrap w-full items-center px-2 md:px-8">
              <div className="flex flex-col md:flex-row items-center w-full lg:w-1/3 text-gray-light mb-12 lg:mb-0">
                <PhoneIcon className="w-16 py-4 md:py-2" />
                <div className="w-full md:pl-8">
                  Bovan d.o.o.
                  <br />
                  Nadin 104A
                  <br />
                  23223 Škabrnja, Zadar
                  <br />
                  Republika Hrvatska
                  <br />
                  OIB: 22664930073
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center w-full lg:w-1/3 text-gray-light mb-12 lg:mb-0">
                <LocationIcon className="w-16 py-4 md:py-2" />
                <div className="w-full md:pl-8">
                  <a
                    className="font-bold hover:text-white hover:underline"
                    href="tel:+385958081583"
                  >
                    (+385) 95 808 1583
                  </a>
                  <br />
                  <a
                    className="font-bold hover:text-white hover:underline"
                    href="mailto:info@kota-olive.com"
                  >
                    info@kota-olive.com
                  </a>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center w-full lg:w-1/3 text-gray-light mb-12 lg:mb-0">
                <ListIcon className="w-16 py-4 md:py-2" />
                <div className="w-full md:pl-8">
                  <p className="body-text">
                    Prijavite se na našu mailing listu
                    <br />
                    Budite informirani o najnovijim
                    <br />
                    vijestima i događajima
                  </p>
                  <br />
                  <NewsletterSignupModal />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-lighter">
          <ContactForm />
        </section>
        <section>
          <Map />
        </section>
      </div>

      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
