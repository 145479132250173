import React, { ReactElement } from 'react';
import GoogleMapReact from 'google-map-react';

export default function Map(): ReactElement {
  return (
    <div>
      <div style={{ height: 'max(40vw, 500px)', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBGJFs1LZhiue-LcjDJOwk0IfxDcmqOmQc' }}
          defaultCenter={{ lat: 44.074968, lng: 15.4634947 }}
          defaultZoom={14}
        />
      </div>
    </div>
  );
}
