import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const matchEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

interface FormState {
  fullName: string;
  email: string;
  title: string;
  content: string;
}

async function submitContactForm(formData: FormState): Promise<any> {
  const resp = await fetch('https://kota-contact.inoric-images.workers.dev', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  return resp.json();
}

interface SentOverlayProps {
  onClose: () => void;
}

const SentOverlay = ({ onClose }: SentOverlayProps): ReactElement => (
  <div className="absolute flex bg-gray-lighter justify-center items-center z-50 left-0 right-0 top-0 bottom-0">
    <div className="flex flex-col h-72 justify-center items-center">
      <h1 className="title-text text-center mb-12">Vaša poruka je poslana.</h1>
      <div className="px-4 py-4 flex justify-center">
        <button type="button" onClick={onClose} className="btn">
          Zatvori
        </button>
      </div>
    </div>
  </div>
);

const emptyState: FormState = {
  email: '',
  title: '',
  fullName: '',
  content: '',
};

export const ContactForm = (): ReactElement => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormState>(emptyState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const setField = <K extends keyof FormState>(key: K, val: FormState[K]) => {
    setFormData({ ...formData, [key]: val });
  };

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    console.log('Submitting: ', formData);
    setSubmitting(true);
    const resp = await submitContactForm(formData);
    console.log('Submitted. Got response: ', resp);
    setSubmitting(false);
    setDone(true);
    setFormData(emptyState);
  };

  const canSubmit = useMemo(
    () =>
      matchEmail.test(formData.email) &&
      formData.fullName.length > 0 &&
      formData.title.length > 0 &&
      formData.content.length > 0,
    [formData],
  );

  return (
    <form
      className="flex relative flex-wrap w-full items-stretch max-w-screen-xl mx-auto px-4 py-8 md:py-32"
      onSubmit={handleSubmit}
    >
      <div className="w-full md:w-1/2  border-gray-dark md:border-r px-4 md:px-8 flex flex-col">
        <div className="mb-12 flex-1">
          <h2 className="above-title-text text-center text-gray-darkerlight mb-6">
            {t('contact.contact_abovetitle')}
          </h2>
          <h1 className="title-text text-center">
            {t('contact.contact_title')}
          </h1>
        </div>

        <div>
          <input
            type="text"
            placeholder={t('contact.contact_name')}
            className="input mb-12"
            value={formData.fullName}
            onChange={(ev) => setField('fullName', ev.target.value)}
          />
          <input
            type="email"
            placeholder={t('contact.contact_email')}
            className="input mb-12"
            value={formData.email}
            onChange={(ev) => setField('email', ev.target.value)}
          />
          <input
            type="text"
            placeholder={t('contact.contact_subject')}
            className="input mb-12 md:mb-0"
            value={formData.title}
            onChange={(ev) => setField('title', ev.target.value)}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col px-4 md:px-8">
        <textarea
          rows={15}
          className="border-gray-medium border px-2 py-1 h-44 md:h-auto"
          value={formData.content}
          onChange={(ev) => setField('content', ev.target.value)}
        />
        <button
          type="submit"
          className="borderless-btn disabled:bg-gold-pale disabled:cursor-not-allowed disabled:hover:bg-gold-pale"
          disabled={!canSubmit || submitting}
        >
          {t('contact.contact_send')}
        </button>
      </div>
      {done && <SentOverlay onClose={() => setDone(false)} />}
    </form>
  );
};
