import React, { ReactElement } from 'react';

export interface SuccesfullSignupProps {
  onClose: () => void;
}

export const SuccesfullSignup = ({
  onClose,
}: SuccesfullSignupProps): ReactElement => (
  <div className="w-full flex flex-col h-72">
    <div className="flex w-full flex-grow px-4 py-2 justify-center items-center">
      <h1 className="title-text text-center">Prijava uspješna!</h1>
    </div>
    <div className="flex w-full justify-end px-4 py-2">
      <button
        type="button"
        onClick={onClose}
        className="text-gray-medium hover:text-gray-dark mr-2 px-4 py-2"
      >
        Zatvori
      </button>
    </div>
  </div>
);
